
/* eslint-disable */

/**
 * @file Renders App and routes the different pages
 */

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ProtectedRoute } from "./protected.route";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";

import Login from './pages/login';
import Home from './pages/home';
import Reservations from './pages/reservations';
import Vouchers from './pages/vouchers';
import Schedule from './pages/schedule';
import Management from './pages/management';
import ManagementType from './pages/managementType';
import Settings from './pages/settings';
import ScanCode from './pages/scanCode';
import Users from './pages/users';
import Invoices from './pages/invoices';
import Documentation from './pages/documentation';
import ManagementEmail from './pages/managementEmail';
import EditEmail from './pages/editEmail';
import EditTemplate from './pages/editTemplate';
import SalesReportMonth from './pages/salesReportMonth';

export default function App() {

  const BACKEND_DOMAIN = 'https://booking.redadviser.xyz'
  //const BACKEND_DOMAIN = 'https://03e5-64-225-73-61.eu.ngrok.io'
  //const BACKEND_DOMAIN = 'https://dev.ticketslots.com'   
  //const BACKEND_DOMAIN = 'http://localhost:1337'
  const FRONTEND_DOMAIN = 'http://localhost:3001'   

  let endpoint

  switch(BACKEND_DOMAIN) {
    case 'http://localhost:1337':
      console.log('Local')

      //endpoint = 'http://localhost:3002/api'
      endpoint = 'http://159.65.89.223:3000/api'

      break        
    case 'https://dev.ticketslots.com':
      console.log('Dev') 

      endpoint = 'http://localhost:3002/api'

      break      
    case 'https://booking.redadviser.xyz':
      console.log('Prod') 

      endpoint = 'http://159.65.89.223:3000/api'
  } 


  const bilheteiraId = sessionStorage.getItem("bilheteira");

  const [token, setToken] = useState('');  
  const [user, setUser] = useState('');
  const [userProfile, setUserProfile] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false); 

  const {pathname, hash, key} = useLocation(); 

  let tokenRequest = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   

  const handleLogin = (event) => {
    console.log(event.target.value)   
    setUser(event.target.value)
  }

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      {console.log ('USER PROFILE:' + userProfile)}
      {console.log ('USER:' + user)} 
          
      <Switch>
        <Route exact path="/" render={() => (<Login BACKEND_DOMAIN={BACKEND_DOMAIN} onLogin={handleLogin}/>)} />
        <ProtectedRoute exact path="/home" component={() => <Home BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile} bilheteiraId={bilheteiraId}/>} />
        <ProtectedRoute exact path="/reservations" component={() => <Reservations BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile} />} />
        <ProtectedRoute exact path="/vouchers" component={() => <Vouchers BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile} />} />        
        <ProtectedRoute exact path="/scancode"  style={{overflow:'hidden'}} component={() => <ScanCode BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile}/>}  />        
        <ProtectedRoute exact path="/schedule" component={() => <Schedule BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile}/>}  />
        <ProtectedRoute exact path="/invoices" component={() => <Invoices BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile}/>}  />        
        <ProtectedRoute exact path="/settings" component={() => <Settings BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile} />} />
        <ProtectedRoute exact path="/users" component={() => <Users BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile}/>}  />        
        <ProtectedRoute exact path="/users/:id/report/:year/:month" component={() => <SalesReportMonth BACKEND_DOMAIN={BACKEND_DOMAIN} FRONTEND_DOMAIN={FRONTEND_DOMAIN} />} />        
        <ProtectedRoute exact path="/service/:id/management" component={() => <Management BACKEND_DOMAIN={BACKEND_DOMAIN} FRONTEND_DOMAIN={FRONTEND_DOMAIN} user={user} userProfile={userProfile} />} />
        <ProtectedRoute exact path='/service/:id/management/:type' component={() => <ManagementType BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile} />}  />
        <ProtectedRoute exact path='/service/:id/management/season/:idSeason/:type' component={() => <ManagementType BACKEND_DOMAIN={BACKEND_DOMAIN} user={user} userProfile={userProfile} />} />
        <ProtectedRoute exact path='/documentation' component={() => <Documentation path={'documentation'}/>} />
        <ProtectedRoute exact path="/service/:id/management-email" component={() => <ManagementEmail endpoint={endpoint} BACKEND_DOMAIN={BACKEND_DOMAIN} FRONTEND_DOMAIN={FRONTEND_DOMAIN} user={user} userProfile={userProfile} />} />
        <ProtectedRoute exact path="/service/:id/management-email/edit-email" component={() => <EditEmail BACKEND_DOMAIN={BACKEND_DOMAIN} FRONTEND_DOMAIN={FRONTEND_DOMAIN} user={user} userProfile={userProfile} />} />
        <ProtectedRoute exact path="/service/:id/management-email/edit-template" component={() => <EditTemplate endpoint={endpoint} BACKEND_DOMAIN={BACKEND_DOMAIN} FRONTEND_DOMAIN={FRONTEND_DOMAIN} user={user} userProfile={userProfile} />} />
        <Route path="*" component={() => "404 NOT FOUND"} />
      </Switch>
    </div>
  );
}

const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
);
