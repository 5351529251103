/* eslint-disable */

/**
 * @file Renders vouchers tab in backoffice
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
//import moment from 'moment';
import moment from 'moment-timezone';
import styled, { css, createGlobalStyle } from "styled-components";
import Select from 'react-select';

import { formatDate, parseDate } from "react-day-picker/moment";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import iconEdit from '../static/icon_editar.svg';
import iconDelete from '../static/icon_lixo.svg';
import iconLeft from '../static/icon_left.svg';
import iconRight from '../static/icon_right.svg';
import iconInfo from '../static/icon_info.png';

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H3,
  H6,
  Text12,
  Loading,
  Checkbox,
} from '../atoms';

import {
  Modal,
  Table
} from '../molecules';


/**
 * Manages rendering of vouchers tab in backoffice
 * 
 * @component Vouchers
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 */
export default function Vouchers(props) {

  let temp = sessionStorage.getItem('token');  


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [vouchers, setVouchers] = useState([])
  const [bilheteiraId, setBilheteiraId] = useState('')
  const [payments, setPayments] = useState('')

  const [isOpen, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});

  //const [currentVouchers, setCurrentVouchers] = useState([])
  //const [pageNumbers, setPageNumbers] = useState([])

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(50);

  const [day, setDay] = useState('');
  const [dayEnd, setDayEnd] = useState('');
  const [dataService, setDataService] = useState([]);
  const [service, setService] = useState('');
  const [search, setSearch] = useState('');

  const [openSubTable, setOpenSubTable] = useState(false);       // Opens row with visitor info
  const [purchaseIndex, setPurchaseIndex] = useState('');  // Sets reservation index to display subtable
  const [tiposBilhete, setTiposBilhete] = useState('')

  const [userData, setUserData] = useState({});                  // User database data
  const [user, setUser] = useState('');
  const [userProfile, setUserProfile] = useState('');

/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  useEffect(() => {
    getCredentials()
    getDate()
    getTiposBilhete(temp)
  }, []);


  useEffect(() => {
    getData(temp);
    getService(temp);    
  }, [userData]);


  useEffect(() => {
      getUserData(temp);
  }, [user]); 

  useEffect(() =>{
    getTicketOfficeData(temp);
  }, [bilheteiraId])


/**
 * @function ?
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * @todo Create function
 */ 
  console.log(vouchers)

  let currentVouchers = [];
  let filtredDataSearch = search === '' ? vouchers : vouchers.filter(filterNameEmail(search));
  let filtredDataService = service === '' ? filtredDataSearch : filtredDataSearch.filter(filterService(service));
  let filtredDataDay = day === '' ? filtredDataService : filtredDataService.filter(filterData(day));
  let filtredData = (day !== '' && dayEnd !== '') ? filtredDataService.filter(filterDataWeek(day, dayEnd)) : filtredDataDay;
  
  let pageNumbers = [];
  let indexOfLast = currentPage * itemPerPage;
  let indexOfFirst = indexOfLast - itemPerPage;
  if (vouchers.length > 0) {
    currentVouchers = filtredData.slice(indexOfFirst, indexOfLast);
    for (let i = 1; i <= Math.ceil(filtredData.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  }

  console.log(currentVouchers)

/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */  

  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;

  let tokenRequest = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let optionsRows = [
    { value: 5, label: '5 items p/page' },
    { value: 10, label: '10 items p/page' },
    { value: 25, label: '25 items p/page' },
    { value: 50, label: '50 items p/page' },
  ];  

//  var pageNumbers = []

/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

/**
 * @callback handleClick - ...
 *
 * CALLED BY:
 * ...
 *
 * TO IMPROVE:
 * ...
 */
  const handleClick = event => {
    setCurrentPage(Number(event.target.id));
  };


/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */  


/**
 * @function filterNameEmail
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
function filterNameEmail(text) {
  return function (x) {
    let val = undefined;
    if (x.nome || x.email) {
      val = x.nome.toLowerCase().includes(text.toLowerCase()) || x.email.toLowerCase().includes(text.toLowerCase());
    }
    if (val === true) {
      return true;
    }
  }
};


/**
 * @function filterData
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
function filterData(search) {
  return function (x) {
    let val = undefined;
    
    if (x.data) {
      val = (moment(x.data).format('YYYY-MM-DD').includes(search));
    }
    if (val === true) {
      return true;
    }
  }
};


/**
 * @function filterDataWeek
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
function filterDataWeek(day, dayEnd) {
  return function (x) {
    let val = undefined;
    if (x.data) {
      val = (moment(x.data).format('YYYY-MM-DD') >= day && moment(x.data).format('YYYY-MM-DD') <= dayEnd);
    }
    if (val === true) {
      return true;
    }
  }
};


/**
 * @function filterService
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
function filterService(id) {
  return function (x) {
    let val = undefined;
    if (x.servico && x.servico.id) {
      val = x.servico.id == id;
    }
    if (val === true) {
      return true;
    }
  }
};


/**
 * @function getCredentials
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getCredentials = () => {
    console.log('GET CREDENTIALS')
    console.log(sessionStorage.getItem('bilheteira'))

    setToken(temp);
    setUser(sessionStorage.getItem('username'))
    setUserProfile(sessionStorage.getItem('user-profile'))
    setBilheteiraId(sessionStorage.getItem('bilheteira'))   
  }

/**
 * @function getTiposBilhete
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getTiposBilhete = (key) => {
    console.log('GET TIPOSBILHETES')

    axios
      .get(BACKEND_DOMAIN + "/tipo-bilhetes" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)

        setTiposBilhete(response.data) 
      })
      .catch(error => {
        console.log(error)

        setLoading(false)
      })           
  }  


/**
 * @function getDate
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getDate = () => {
    if (props.location && props.location.data) {
      setDay(props.location.data);
    }
  }


/**
 * @function getData
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getData = (key) => {
    setLoading(true);

    axios
      .get(`${BACKEND_DOMAIN}/compras`, {
        params: {
          status: true,
          isVouchers: true,   // Include the `isVouchers` query parameter
          _limit: -1           // Include the `_limit` query parameter
        },
        headers: {
          Authorization: `Bearer ${key}`  // Ensure the correct authorization header
        }
      })
      .then(response => {
        let tempArr = response.data;
        let userServices = userData.servicos
        //tempArr = tempArr.filter(item => item.isVouchers)

        console.log(tempArr)
        console.log(userData)
        //console.log(userServices)


        const filteredTempArr = tempArr.filter(voucher => {
          let include = false

          if (userServices.find(s => s.id == voucher.servico.id) != undefined) {
            include = voucher.servico.id == userServices.find(s => s.id == voucher.servico.id).id
          }

          //console.log(include)

          return include
        })

        console.log('TEST')
        console.log(filteredTempArr)

        filteredTempArr.sort(function (a, b) {
          if (a.created_at > b.created_at) {
            return -1;
          }
          if (a.created_at < b.created_at) {
            return 1;
          }
          return 0;
        });
        
        setVouchers(filteredTempArr);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

      });
  };


/**
 * @function getUserData
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * -
 */ 
  const getUserData = (key) => {
  setLoading(true);

  axios
      .get(BACKEND_DOMAIN + "/users" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(user)
        console.log(response.data)
        console.log(response.data.find(u => u.username==user))

        const userObject = response.data.find(u => u.username==user)

        setUserData(userObject)
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });  
}      


/**
 * @function passData
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const passData = (data, type) => {
    setDataModal(
      {
        content: data,
        typeContent: type,        
        token: token
      }
    );
    setTimeout(toogleModal(), 500);
  };


/**
 * @function toogleModal
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const toogleModal = () => {
    setIsOpen(!isOpen);
  };


/**
 * @function getService
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * @todo Create function
 */ 
  const getService = (key) => {
    setLoading(true);
    axios
      .get(BACKEND_DOMAIN + "/servicos" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        let temp = [];
        for (let i = 0; i < response.data.length; i++) {
          temp.push({
            value: response.data[i].id,
            label: response.data[i].nome
          })
        }

        console.log(temp)
        console.log(userData)

        let filteredTemp = temp.filter(service => {
          let include = false

          if (userData.servicos.find(s => s.id == service.value) != undefined)
            include = service.value == userData.servicos.find(s => s.id == service.value).id

          return include
        })

        console.log(filteredTemp)        

        filteredTemp.unshift({
          value: '',
          label: 'All'
        })
        
        setDataService(filteredTemp);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };


/**
 * @function prevPage
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * ...
 */ 
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


/**
 * @function nextPage
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * ...
 */
  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

/**
 * @function getTicketOfficeData
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * ...
 */
  const getTicketOfficeData = (key) => {
   axios
      .get(BACKEND_DOMAIN + "/bilheteiras/" + bilheteiraId + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)

        setPayments(response.data.Pagamentos)
      })
  };

  return (
    <Section>   
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper>
          <Container>         
            <DivHeader>
              <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
                Vouchers
              </H3>
            </DivHeader>
            <DivFilterAdd>
              <DivGLobalDate>
                <DivDate>
                  <Text12
                    dangerouslySetInnerHTML={{ __html: 'Service' }}
                  />
                  <Margin margin={1} />
                  <Dropdown
                    placeholder='Service'
                    name='slot'
                    classNamePrefix='selectBox'
                    options={dataService}
                    onChange={dataService => setService(dataService.value)}
                    defaultValue={dataService[0]}
                  />
                </DivDate>
                <DivDate>
                  <Text12
                    dangerouslySetInnerHTML={{ __html: 'Date' }}
                  />
                  <Margin margin={1} />
                  <InputDate
                    inputProps={{ className: 'input-date' }}
                    onDayChange={(day) => setDay(moment(day).format('YYYY-MM-DD'))}
                    format={'DD-MM-YYYY'}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder='Select date to filter'
                    value={day}
                  />
                </DivDate>
                <DivDate>
                  <Text12
                    dangerouslySetInnerHTML={{ __html: 'Date End' }}
                  />
                  <Margin margin={1} />
                  <InputDate
                    inputProps={{ className: 'input-date' }}
                    onDayChange={(dayEnd) => setDayEnd(moment(dayEnd).format('YYYY-MM-DD'))}
                    format={'DD-MM-YYYY'}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder='Select date to filter'
                    value={dayEnd}
                  />
                </DivDate> 
              </DivGLobalDate>
            </DivFilterAdd>
            <Margin margin={2} />
            <DivFilterAdd>
              <DivDate>
                <Text12
                  dangerouslySetInnerHTML={{ __html: 'Search by Name or Email' }}
                />
                <Margin margin={1} />
                <TextInput
                  onChange={(event) => setSearch(event.target.value)}
                  value={search}
                  placeholder="name or email"
                />
              </DivDate>
            </DivFilterAdd>
            <Margin margin={4} />
            <DivTabela >
              <Table >
                <thead>
                  <tr>
                    <th></th>                              
                    <th style={{textAlign: 'center'}}>Purchase ID</th>                                
                    <th style={{textAlign: 'center'}}>Name</th>
                    <th style={{textAlign: 'center'}}>Email</th> 
                    <th style={{textAlign: 'center'}}>Phone</th>                                                                   
                    <th style={{textAlign: 'center'}}>Quantity</th>
                    <th style={{textAlign: 'center'}}>Purchased at</th>  
                    <th style={{textAlign: 'center'}}>Vat</th> 
                    <th style={{textAlign: 'center'}}></th> 
                  </tr>
                </thead>

                {console.log(currentVouchers)}
                
                {currentVouchers.length > 0 &&
                  <>
                    {currentVouchers.map((purchase, index) => (
                      <>
                        <tbody>
                          <tr key={String(index)} style ={{height: '60px'}}> 
                            <td className='buttons'>
                              <ButtonInfo 
                                onClick={() => {
                                  setOpenSubTable(!openSubTable)
                                  setPurchaseIndex(index)
                                }}
                              >
                                <Icon src={iconInfo} />                                           
                              </ButtonInfo>
                            </td>                                                     
                            <td>{purchase.id}</td>
                            <td style={{textAlign: 'left', maxWidth: '220px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{purchase.nome}</td>
                            <td style={{textAlign: 'left', maxWidth: '220px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{purchase.email}</td>
                            <td>{purchase.contacto}</td>                              
                            <td>{purchase.quantidade}</td>                          
                            <td>{moment(purchase.created_at).utc().tz("Europe/Lisbon").format('YYYY-MM-DD HH:mm')}</td>
                            <td>{purchase.nif}</td>
                          </tr>
                        </tbody>
                        {openSubTable==true && index==purchaseIndex && 
                          <>
                            <thead style={{border: 'none'}}>
                              <tr >
                                <th></th>
                                <th >Voucher N.º</th>
                                <th >Ticket Type</th>                                                                                                                                                           
                              </tr>                        
                            </thead>
                            <tbody>
                              {purchase.vouchers
                                .sort((a, b) => a.id - b.id)
                                .map((voucher, index) => (                              
                                <tr >
                                  <td></td>
                                  <td>{voucher.id}</td>
                                  <td>
                                    {tiposBilhete.find(item => item.id == voucher.tipo_bilhete) != undefined ?
                                      tiposBilhete.find(item => item.id == voucher.tipo_bilhete).tipoBilheteEN : 
                                      ''
                                    }
                                  </td>                                                                                                                                                            
                                </tr>
                              ))}                                   
                            </tbody>
                          </>
                        }                      
                      </>
                    ))}  
                  </>                  
                }
              </Table>
            </DivTabela>
            {currentVouchers.length === 0 &&
              <H6 style={{ padding: '40px 0', border: '1px solid #E4EBF0' }} font='SFRegular' align="center" dangerouslySetInnerHTML={{ __html: 'No items to display' }} />
            }
            <DivFooter>
              <DropdownItems
                rows={true}
                placeholder='Rows'
                name='rows'
                classNamePrefix='selectBox'
                options={optionsRows}
                onChange={optionsRows => setItemPerPage(optionsRows.value)}
                value={optionsRows.filter(data => (data.value === itemPerPage)) ? optionsRows.filter(data => (data.value === itemPerPage)) : ''}
              />
              {pageNumbers.length != 0 && 
                <DivPagination>
                  <Arrow onClick={prevPage} left>
                    <img src={iconLeft} />
                  </Arrow>
                  {console.log(pageNumbers)}
                  {pageNumbers.map((number, index) => (
                    <>
                      {(number.value==pageNumbers.length && 
                        currentPage < pageNumbers.length - 2) && 
                        <span style={{marginRight:'10px'}}>...</span>
                      }                   
                      {(number.value==1 || number.value==pageNumbers.length || ((number.value >= currentPage - 1) && (number.value <= currentPage + 1))) &&
                        <NumberText
                          key={String(index)}
                          id={number.value}
                          onClick={handleClick}
                          className={currentPage === number.value ? "selected" : ""}
                        >
                          {number.label}
                        </NumberText> 
                      } 
                      {number.value==1 && 
                        currentPage > 3 &&
                        <span style={{marginRight:'10px'}}>...</span>
                      }                     
                    </>    
                  ))}
                  <Arrow onClick={nextPage} right>
                    <img src={iconRight} />
                  </Arrow>                
                </DivPagination>
              }
            </DivFooter>
          </Container>
          {isOpen &&
            <Modal
              isOpen={isOpen}
              {...dataModal}
              token={token}
              toogleModal={toogleModal}
              vouchers={vouchers}
              setVouchers={setVouchers}
              user={user}
              setUser={setUser}
              setUserProfile={setUserProfile}
              userProfile={userProfile}       
              dataService={dataService}        
              BACKEND_DOMAIN={BACKEND_DOMAIN}
              payments={payments}
            />
          }
        </Wrapper>
      }
    </Section>
  );
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */
const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin-bottom:24px;
`;
const DivFilterAdd = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  @media screen and (max-width:1080px){
    flex-flow:column;
    align-items:flex-start;
  }
`;
const DivGLobalDate = styled.div`
  display:flex;
  flex-direction:row;
  @media screen and (max-width:1080px){
    flex-direction:column;
  }
`;
const DivDate = styled.div`
  display:flex;
  flex-flow:column;
  margin-right:20px;
  :last-child{
    margin-right:00px;
  }
  @media screen and (max-width:1080px){
    width:100%;
    margin-right:0;
    margin-bottom:20px;
  }
`;
const InputDate = styled(DayPickerInput)`
  &.input-date{
    height:48px;
    padding:16px;
    box-sizing: border-box;
    font-family: SFRegular;
  }
  input{
    height:48px !important;
    font-family: SFRegular;

  }
`;

const TextInput = styled.input.attrs(props => ({
  type: "text"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:450px;
  max-width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;
const DivTabela = styled.div`
  width:100%;
  overflow:scroll;
  height:calc(300px + (650 - 400) * ((100vw - 760px) / (1920 - 760)));
  @media screen and (max-width: 759px){
    height:350px;
  }
`;

const Icon = styled.img`
  width:14px;
  height:14px;
  margin-right:8px;
  z-index: 10;
`;
const ButtonAdd = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 16px 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  height:48px;
  box-sizing: border-box;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
  @media screen and (max-width:570px){
    padding: 16px 30px;
    margin-top:8px;
  }
`;
const ButtonEdit = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 0px 8px 10px;
  display: flex;
  align-items: center;
  : center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  max-height: 14px;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;
const ButtonInfo = styled.div`
  margin-left: 5px;
  background: #63666A;
  border-radius: 2px;
  padding: 8px 2px 8px 10px;
  display: flex;
  align-items: center;
  : center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  max-height: 14px;  
  :hover{
    background: #393D47;
    cursor: pointer;
  }
`;
const ButtonDelete = styled.div`
  margin-left: 5px;
  background: #EBEBEB;
  border-radius: 2px;
  padding: 8px 2px 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  max-height: 14px;  
  :hover{
    background: #A9A9A9;
    cursor: pointer;
  }
`;
const DivPagination = styled.div`
  margin-top:-10px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 50px;
  flex-wrap:wrap;
`;
const NumberText = styled.div`
  height:48px;
  width:48px;
  margin-right: 10px;
  margin-top: 10px;
  background: #EBEBEB;
  border:1px solid #EBEBEB;
  color: #A9A9A9;
  box-sizing: border-box;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.4s ease;
  &.selected {
    color: #373737;
    background: #FFCE20;
  }
  :last-of-type {
    margin-right: 0;
  }
  :hover{
    cursor: pointer;
    color: #373737;
    border:1px solid #A9A9A9;
  }
`;
const Arrow = styled.div`
  height:48px;
  width:48px;
  border:1px solid #EBEBEB;
  background: #EBEBEB;
  box-sizing:border-box;
  border-radius: 2px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top: 10px;
  color:#A9A9A9;
  transition: all 0.4s ease;
  ${({ left }) => left && css`
    margin-right: 10px;
  `}
  ${({ right }) => right && css`
    margin-right: 10px;
  `}
  :hover{
    cursor:pointer;
    border:1px solid #A9A9A9;
    color:#373737;
  }
`;
const Dropdown = styled(Select)`
  width: 250px;
  font-family: SFMedium;
  margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  margin-right: ${({ rows }) => rows ? '16px' : '0'};
  div{
    border-color: #E4EBF0;
    min-height:36px;
  }
  .selectBox__control {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4E5D6B;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    height:48px;
    .selectBox__value-container {
      border-radius: 0;
      align-items: center;
      padding:16px 24px;
      box-sizing: border-box;
      height: 48px;
      .selectBox__input {
        display: flex !important;
        align-items: center !important;
      }
      .selectBox__single-value {
        display: flex;
        align-items: center;
      }
      .selectBox__placeholder {
        display: flex;
        align-items: center;
      }
    }
    .selectBox__indicators {
      height:48px;
      width:48px;
      background: #F5F7F7;
      justify-content:center;
      .selectBox__indicator-separator {
        display: none;
      }
      .selectBox__dropdown-indicator {
        display:flex;
        align-items: center;
        svg {
          transition: all 400ms ease;
          transform: rotate(0);
        }
      }
      &:hover {
        background-color: #E4EBF0;    
      }
    }
    :hover{
      border-color:#EBEBEB;
    }
  }
  .selectBox__menu {
    border-radius: 0;
    margin-top: 4px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    z-index: 2;
    .selectBox__menu-list {
      background: #fff !important;
      margin:0;
      .selectBox__option {
        font-size: 14px;
        display: flex;
        align-items: center;
        &.selectBox__option--is-selected {
          background-color: #d8d8d8;
          color: #000;
        }
        &.selectBox__option--is-focused {
          background-color: #eaeaea;
          color: #000;
        }
        &:hover {
          cursor: pointer;
          background: #E4EBF0;
        }
      }
    }
  }
  .selectBox__control--is-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #F5F7F7;
    box-shadow: none;
    &:hover {
      border-color: #F5F7F7;
    }
  }
  .selectBox__control--menu-is-open {
    .selectBox__indicators {
      .selectBox__dropdown-indicator {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
  @media screen and (max-width:570px){
    margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  }
`;
const DropdownItems = styled(Select)`
  width: 250px;
  min-width:200px;
  font-family: SFMedium;
  margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  margin-right: ${({ rows }) => rows ? '16px' : '0'};
  div{
    border-color: #E4EBF0;
    min-height:16px;
  }
  .selectBox__control {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4E5D6B;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    height:48px;
    .selectBox__value-container {
      border-radius: 0;
      align-items: center;
      padding:16px 24px;
      box-sizing: border-box;
      height: 48px;
      .selectBox__input {
        display: flex !important;
        align-items: center !important;
      }
      .selectBox__single-value {
        display: flex;
        align-items: center;
      }
      .selectBox__placeholder {
        display: flex;
        align-items: center;
      }
    }
    .selectBox__indicators {
      height:48px;
      width:48px;
      background: #F5F7F7;
      justify-content:center;
      .selectBox__indicator-separator {
        display: none;
      }
      .selectBox__dropdown-indicator {
        display:flex;
        align-items: center;
        svg {
          transition: all 400ms ease;
          transform: rotate(0);
        }
      }
      &:hover {
        background-color: #E4EBF0;    
      }
    }
    :hover{
      border-color:#EBEBEB;
    }
  }
  .selectBox__menu {
    border-radius: 0;
    margin-top: 4px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    z-index: 2;
    top:-160px;
    .selectBox__menu-list {
      background: #fff !important;
      margin:0;
      .selectBox__option {
        font-size: 14px;
        display: flex;
        align-items: center;
        min-height:36px;
        &.selectBox__option--is-selected {
          background-color: #d8d8d8;
          color: #000;
        }
        &.selectBox__option--is-focused {
          background-color: #eaeaea;
          color: #000;
        }
        &:hover {
          cursor: pointer;
          background: #E4EBF0;
        }
      }
    }
  }
  .selectBox__control--is-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #F5F7F7;
    box-shadow: none;
    &:hover {
      border-color: #F5F7F7;
    }
  }
  .selectBox__control--menu-is-open {
    .selectBox__indicators {
      .selectBox__dropdown-indicator {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
  @media screen and (max-width:570px){
    margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  }
`;
const DivFooter = styled.div`
  display:flex;
  flex-flow:row;
  margin-top:14px;
`;
const DivCheckbox = styled.div`
  max-width: 78px;
  display:flex;
  flex-flow:row;
  align-items:center;
  padding: 8px 17px;
  box-sizing:border-box;
  background: #EBEBEB;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #A9A9A9;
  :hover{
    background: #F8C100;
    cursor:pointer;
  }
  ${({ checked }) => checked && css`
    background:#FFCE20;
    color: #373737;
  `}
`;